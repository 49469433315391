<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import LoadingSpinner from '@/components/Loading';
import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
import StateOption from "@/components/forms/state-option";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import MessageModal from "@/components/modals/message-modal";
// import CreateForm from "@/components/forms/create-form";

export default {
	name: "Edit_tenancy",
	components: {
		Layout,
		PageHeader,
		LoadingSpinner,
		InputGroup,
		SelectGroup,
		StateOption,
		MessageModal,
		// CreateForm
	},
	data() {
		return {
			title: `Add CTC`,
			items: [
				{
					text: "CTC",
				},
				{
					text: 'Add',
					active: true,
				},
			],
			isLoading: false,
			ctcFields: [
				{
					name: "member_id",
					id: "member_id",
					label: "For member",
					type: "select",
					errors: "member_id",
					options: []
				},
				{
					name: "tenancy_id",
					id: "tenancy_id",
					label: "Assign to which Tenancy?",
					type: "select",
					errors: "tenancy_id",
					options: [],
				},
				{
					name: "ctc_quantity",
					id: "ctc_quantity",
					label: "Quantity",
					type: "number",
					errors: "ctc_quantity",
				},
				// {
				// 	name: "status",
				// 	id: "status",
				// 	label: "Status",
				// 	type: "select",
				// 	errors: "status",
				// 	options: [
				// 		{ name: "Paid", value: "paid" },
				// 		{ name: "Processing", value: "processing" },
				// 		{ name: "Shipped", value: "shipped" },
				// 		{ name: "Delivered", value: "delivered" },
				// 		{ name: "Pending Payment", value: "pending_payment" },
				// 	]
				// },
				{
					name: "shipping_name",
					id: "shipping_name",
					label: "Recipient's Name",
					type: "text",
					errors: "shipping.shipping_name",
				},
				{
					name: "shipping_phone",
					id: "shipping_phone",
					label: "Phone",
					type: "number",
					errors: "shipping.shipping_phone"
				},
				{
					name: "shipping_address_line_1",
					id: "shipping_address_line_1",
					label: "Address Line 1",
					type: "text",
					errors: "shipping.shipping_address_line_1",
				},
				{
					name: "shipping_address_line_2",
					id: "shipping_address_line_2",
					label: "Address Line 2",
					type: "text",
					errors: "shipping.shipping_address_line_2",
				},
				{
					name: "shipping_postal_code",
					id: "shipping_postal_code",
					label: "Postal Code",
					type: "number",
					errors: "shipping.shipping_postal_code",
				},
				{
					name: "shipping_city",
					id: "shipping_city",
					label: "City",
					type: "text",
					errors: "shipping.shipping_city",
				},
				{
					name: "shipping_state_id",
					id: "shipping_state_id",
					label: "State",
					type: "select",
					errors: "shipping.shipping_state_id",
					options: []
				},
				{
					name: "shipping_country_id",
					id: "shipping_country_id",
					label: "Country",
					type: "select",
					errors: "shipping.shipping_country_id",
					options: []
				},
				{
					name: "courier_company",
					id: "courier_company",
					label: "Courier Company",
					type: "text",
					errors: "shipping.courier_company",
				},
				{
					name: "shipping_tracking_no",
					id: "shipping_tracking_no",
					label: "Shipping Tracking No",
					type: "text",
					errors: "shipping.shipping_tracking_no",
				},
			],
			order_Id: "",
			formData: {
				"member_id": null,
				"tenancy_id": null,
				"ctc_quantity": null,
				"shipping_name": null,
				"shipping_phone": null,
				"shipping_state_id": null,
				"shipping_country_id": null,
				"shipping_postal_code": null,
				"shipping_address_line_1": null,
				"shipping_address_line_2": null,
				"shipping_city": null,
				"courier_company": null,
				"shipping_tracking_no": null,
			},
			errors: {},
			stateKey: 0,
			state: 0,
			selectedTenancy: null,
			ctc_id: 0,
			state_error: '',
			redirect: false,
			countryOptions: [],
		};
	},
	methods: {
		async getTenancy(member_id) {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/from-member/${member_id}`, {
				headers: authHeader(),
				params: {
					"type": "tenancy",
					"length": 0,
					"page": 1
				}
			}).catch(function (error) {
				alert("Load error:" + error.message);
			});

			if (result.data.code === 'success') {
				const { data } = result.data.data.data;
				let tenancy = data.map(e => { return { "name": e.tenancy.order_display_id, "value": e.tenancy.order_id } });
				this.ctcFields[1].options = tenancy;
			}
			this.isLoading = false;
		},

		async retrieveCountries() {
            this.loading = true;
            let countries = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/country`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    countries = result['data'];
                    console.log('this.countryOptions', this.countryOptions)
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return countries;
        },

		async loadCtcDetails(id) {
			this.isLoading = true;
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/ctc/${id}`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Load Ctc error:" + error.message);
			});
			if (result.data.code === 'success') {
				Object.keys(this.formData).forEach(e => {
					if (Object.prototype.hasOwnProperty.call(result.data.data, e)) {
						this.formData[e] = result.data.data[e];
					}
				});
				this.formData.tenancy_id = result.data.data.tenancy_detail.id;
				this.formData.ctc_quantity = result.data.data.ctc.ctc_quantity;
				this.ctc_id = result.data.data.ctc.id;
				this.state = result.data.data.shipping_state_id;
				this.stateKey += 1;
			}
			this.isLoading = false;
		},

		async save() {
			this.errors = {};
			this.isLoading = true;
			const col = ['member_id', 'tenancy_id', 'ctc_quantity'];
			let data = { shipping: {} };
			Object.keys(this.formData).forEach(element => {
				if (col.includes(element)) {
					data[element] = this.formData[element];
				} else {
					// if (this.formData.status !== 'pending_payment') {
					data.shipping[element] = this.formData[element];
					// }
				}
			});
			let action = null;
			let url = null;
			//add
			if (this.$route.name !== 'Ctc Edit') {
				action = 'post';
				url = 'ctc/create-ctc';
			}
			//edit
			else {
				action = 'put';
				url = `ctc/update-ctc/${this.ctc_id}`;
			}

			const result = await axios[action](`${process.env.VUE_APP_APIURL}/${url}`, data, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("save error:" + error.message);
			});

			if (result.data.code === 'success') {
				this.redirect = true;
				this.order_Id = result.data.data.id;
				this.$refs.messageModal.showModal('The record has been added');
			} else if (result.data.code === 'invalid_field') {
				this.errors = result.data.errors;
			} else if (result.data.code === 'permission_denied') {
				this.redirect = false;
				this.$refs.messageModal.showModal('Permmission Denied');
			}
			this.isLoading = false;
		},

		redirectPage() {
			if (this.redirect)
				this.$router.push({ 'name': "Ctc View", params: { id: this.order_Id } })
		}
	},
	async created() {
		if (this.$route.name === 'Ctc Edit') {
			this.loadCtcDetails(this.$route.params.id);
		}

		this.retrieveCountries().then((countries) => {
            this.countryOptions = countries.map((country) => ({
                name: country.name,
                value: country.id,
            }));
        });

		const result = await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
			headers: authHeader(),
		}).catch(function (error) {
			alert("Load Member error:" + error.message);
		});

		if (result.data.code === 'success') {
			let member = result.data.data.map(e => { return { "value": e.id, "name": e.full_name }; });
			this.ctcFields[0].options = member;
		}
	},
	computed: {
		formDataToWatch() {
			return Object.assign({}, this.formData);
		}
	},
	watch: {
		formDataToWatch: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal.member_id != oldVal.member_id) {
					if (newVal.member_id) {
						this.isLoading = true;
						this.getTenancy(newVal.member_id);
					}
				}

				// if (newVal.status != oldVal.status) {
				// 	if ((newVal.status != 'pending_payment' || newVal.status != 'paid') && newVal.status) {
				// 		for (let i = this.ctcFields.length - 2; i < this.ctcFields.length; i++) {
				// 			this.ctcFields[i].visibility = true;
				// 		}
				// 	} else {
				// 		for (let i = this.ctcFields.length - 2; i < this.ctcFields.length; i++) {
				// 			this.ctcFields[i].visibility = false;
				// 		}
				// 	}
				// }
			}
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<LoadingSpinner :isLoading="isLoading" />
		<MessageModal ref="messageModal" @CloseBtn="redirectPage" />

		<div class="row mb-5">
			<div class="col-lg-12">
				<div class="card mb-5" style="border-radius: 15px;">
					<div class="card-body">
						<div class="row mt-2">
							<div class="col-12 col-md-4" v-for="field in ctcFields" :key="field.id">
								<template v-if="field.name === 'shipping_state_id'">
									<StateOption v-model="formData.shipping_state_id" :stateValue='state'
										:error="errors[field.errors]" :key="stateKey"></StateOption>
								</template>
								<template v-else-if="field.name === 'shipping_country_id'">
									<SelectGroup :id="field.id" :displayName="field.label"
										v-model="formData[field.name]" :error="errors[field.errors]"
										:options="countryOptions" />
								</template>
								<template v-else>
									<InputGroup :type="field.type" :id="field.id" :displayName="field.label"
										v-model="formData[field.name]" :error="errors[field.errors]"
										v-if="field.type === 'text' || field.type === 'number'"
										v-show="field.hasOwnProperty('visibility') ? field.visibility : true" />
									<SelectGroup :id="field.id" :displayName="field.label"
										v-model="formData[field.name]" :error="errors[field.errors]"
										:options="field.options" v-else
										v-show="field.hasOwnProperty('visibility') ? field.visibility : true" />
								</template>
							</div>

							<div class="col-12 mt-3">
								<button class="btn btn-orange" @click="save">
									<i class="mdi mdi-plus-thick"></i>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>